<template>
  <div class="index-page">
    <a-config-provider :locale="locale" :get-popup-container="popContainer">
      <router-view v-if="isRouterAlive" />
    </a-config-provider>
  </div>
</template>

<script>
import { enquireScreen } from './utils/util'
import { mapState, mapMutations } from 'vuex'
import themeUtil from '@/utils/themeUtil';
import { getI18nKey } from '@/utils/routerUtil'
import { request } from '@/utils/request'

import { getRoutesConfig } from '@/services/user'
import { setAuthorization } from '@/utils/request'
import { loadRoutes } from '@/utils/routerUtil'
// import routerMap from '@/router/async/router.map'
import { checkAuthorization } from '@/utils/request'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      locale: {},
      ws: null,
      isRouterAlive: true,
      isReload: true,
      urlToken:"",
    }
  },
  created() {
    if (this.$route.name == '404') {
      this.$router.push('/dashboard/workplace')
      return false;
    }
    this.setHtmlTitle()
    this.setLanguage(this.lang)
    enquireScreen(isMobile => this.setDevice(isMobile))
  },
  mounted() {
    // 获取用户信息
    if (checkAuthorization()) {
      this.getUserInfo();
    }

    // this.setWeekModeTheme(this.weekMode)

  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val)
    },
    lang(val) {
      this.setLanguage(val)
      this.setHtmlTitle()
    },
    $route() {
      this.setHtmlTitle()
    },
    'theme.mode': function (val) {
      let closeMessage = this.$message.loading(`您选择了主题模式 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage)
    },
    'theme.color': function (val) {
      let closeMessage = this.$message.loading(`您选择了主题色 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage)
    },
    'layout': function () {
      window.dispatchEvent(new Event('resize'))
    }
  },
  computed: {
    ...mapState('setting', ['layout', 'theme', 'weekMode', 'lang'], 'account', ['user']),
  },
  beforeDestroy() {
  },
  methods: {
    ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles']),
    getUserInfo() {
      request(process.env.VUE_APP_API_BASE_URL + 'srm/login', 'get').then(res => {
        this.afterLogin(res);
      })
    },

    afterLogin(res) {
      this.logging = false
      this.isReload = false;
      const loginRes = res.data
      if (loginRes.code == 200) {
        res.data.permissions = [{ "id": "queryForm", "operation": loginRes.data.authorityList }]
        res.data.roles = [{ "id": "admin", "operation": loginRes.data.menuList }]
        this.$store.commit('account/setPermissions', { "id": "queryForm", "operation": loginRes.data.authorityList })
        this.setUser(loginRes.data)
        this.setPermissions(loginRes.permissions)
        this.setRoles(loginRes.roles)
        if (loginRes.data.token) {
          setAuthorization({ token: loginRes.data.token,  expireAt: new Date(new Date().getTime() + 12 * 60 * 60 * 1000)  })
        }
        let selectRoutes = [];
        // let routesList = [], selectRoutes = []
        // for (var key in routerMap) {
        //   routesList.push(key)
        // }
        console.log(this.$route, 'this.$route')

        let routesList = loginRes.data.menuList
        getRoutesConfig().then(result => {
          let routesChildren = result.data.data;
          // 接口返回的所有菜单
          routesChildren.forEach(item => {
            // 有权限的菜单
            routesList.forEach(son => {
              if (item.perms == son) {
                if (item.children) {
                  let arr = []
                  item.children.forEach(sons => {
                    if (routesList.includes(sons.perms) && sons.perms.type != 3) {
                      arr.push(sons)
                    }
                  })
                  selectRoutes.push({
                    router: item.perms,
                    children: arr
                  })
                } else {
                  selectRoutes.push({
                    router: item.perms
                  })
                }
              }
            })
          })
          selectRoutes.forEach(item => {
            if (item.children) {
              item.children.forEach((son, index) => {
                if (son.perms == 'product_spu') {
                  item.children.push({
                    path: 'product_spu_form',
                    router: 'product_spu_form',
                    invisible: true,
                  })
                  item.children.push({
                    path: 'product_spu_formEdit',
                    router: 'product_spu_formEdit',
                    invisible: true,
                  })
                }
                if (son.perms == 'spuCombination') {
                  item.children.push({
                    path: 'product_spuCombination_form',
                    router: 'product_spuCombination_form',
                    invisible: true,
                  })
                  item.children.push({
                    path: 'product_spuCombination_formEdit',
                    router: 'product_spuCombination_formEdit',
                    invisible: true,
                  })
                  item.children.push({
                    path: 'product_spu_formDetails',
                    router: 'product_spu_formDetails',
                    invisible: true,
                  })
                }
                if (son.perms == 'product_sku') {
                  item.children.push({
                    path: 'product_sku_formDetails',
                    router: 'product_sku_formDetails',
                    invisible: true,
                  })
                }
                let obj = {
                  router: son.perms,
                  path: son.perms
                }
                if (son.perms == 'product_spuCombination_form' || son.perms == 'product_spuCombination_formEdit' || son.perms == 'product_spu_form' || son.perms == 'product_spu_formEdit' || son.perms == 'product_spu_formDetails' || son.perms == 'product_spu_formDetails' || son.perms == 'product_sku_formDetails') {
                  obj.invisible = true;
                }
                item.children[index] = obj
              })
            }
          })
          console.log(selectRoutes, 'selectRoutes')
          const order = ['dashboard', 'warehouse', 'resources', 'product', 'srm_orders', 'srm_deliveryRules', 'srm_mine', 'srm_setting'];
          selectRoutes.sort((a, b) => {
            const indexA = order.indexOf(a.router);
            const indexB = order.indexOf(b.router);
            return indexA - indexB;
          });
          selectRoutes.unshift({ "router": "dashboard", "children": ["workplace"] })
          const routesConfig = [{
            router: 'root',
            children: selectRoutes
          }]
          console.log(routesConfig, 'routesConfig')
          console.log(selectRoutes, 'selectRoutes')
          loadRoutes(routesConfig)
        })
        // 我的商品
        const routesConfig = [{
          router: 'root',
          children: selectRoutes
        }]
        loadRoutes(routesConfig)
      } else if (loginRes.code == 401) {
        if (this.$route.path !== '/login') {
          this.$router.replace('/login')
        }
        this.$message.error(loginRes.message)
      } else {
        this.error = loginRes.message
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      })
    },
    ...mapMutations('setting', ['setDevice']),
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add('week-mode')
      } else {
        document.body.classList.remove('week-mode')
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang
      switch (lang) {
        case 'CN':
          this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
          break
        case 'HK':
          this.locale = require('ant-design-vue/es/locale-provider/zh_TW').default
          break
        case 'US':
        default:
          this.locale = require('ant-design-vue/es/locale-provider/en_US').default
          break
      }
    },
    setHtmlTitle() {
      const route = this.$route
      const key = route.path === '/' ? 'home.name' : getI18nKey(route.matched[route.matched.length - 1].path)
      console.log(key, 'key')
      document.title = process.env.VUE_APP_NAME + ' | ' + this.$t(key)
      // 获取当前 URL 的哈希部分
      const hash = window.location.hash;
      const queryString = hash.split('?')[1];
      if (queryString) {
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');  // 获取 token 的值
        if (token && token != this.urlToken) {
          this.urlToken = token;
          console.log(token, 'tokentokentokentoken')
          setAuthorization({ token: token, expireAt: new Date(new Date().getTime() + 12 * 60 * 60 * 1000)})
          this.getUserInfo()
          this.$router.replace('/dashboard/workplace')
        }
      }
      // setTimeout(()=>{
      //   console.log( this.isReload,' this.isReload')
      //   if (key == '*.name' && this.isReload) {
      //     this.$router.push('/dashboard/workplace')
      //   }
      // },800)
    },
    popContainer() {
      return document.getElementById("popContainer")
    }
  }
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

::v-deep .ant-card-bordered {
  border: none !important;
}

.ant-card-bordered {
  border: none !important;
}

::v-deep .ant-layout-header {
  height: 44px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.text-wrap2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 控制显示的行数 */
  overflow: hidden;
}
</style>
