import axios from 'axios'
import Cookie from 'js-cookie'
// 跨域认证信息 header 名
const xsrfHeaderName = 'Authorization'
import { message } from 'ant-design-vue';
import store from '../store'

import {initRouter} from '../router'
const router = initRouter(store.state.setting.asyncRoutes)


axios.defaults.timeout = 5000
axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = xsrfHeaderName
axios.defaults.xsrfCookieName = xsrfHeaderName

// 认证类型
const AUTH_TYPE = {
  BEARER: 'Bearer',
  BASIC: 'basic',
  AUTH1: 'auth1',
  AUTH2: 'auth2',
}

// http method
const METHOD = {
  GET: 'get',
  POST: 'post',
  DELETE:'delete'
}

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function request(url, method, params, config) {
  try {
    switch (method) {
      case METHOD.GET:
        return await axios.get(url, { params, ...config });
      case METHOD.POST:
        return await axios.post(url, params, config);
      case METHOD.DELETE:
        return await axios.delete(url);
      default:
        return await axios.get(url, { params, ...config });
    }
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
}
function handleRequestError(error) {
  if (error.response) {
    // 处理有错误响应的情况
    const status = error.response.status;
    const errorMessage = error.response.data.message;
    console.log('Error Status Code:', status);
    message.error(errorMessage)
    console.log('Error Message:', errorMessage);
    // 根据不同的状态码执行不同操作
    switch (status) {
      case 400:
        break;
      case 401:
        message.error('登录失效，请重新登录');
        router.replace('/login')
        break;
      case 403:
        message.error('暂无权限');
        break;
      default:
        message.error(errorMessage);
        // 执行默认的错误处理逻辑
    }
  } else {
    // 处理没有错误响应的情况
    console.error('Error:', error.message);
  }
}
// async function request(url, method, params, config) {
//   switch (method) {
//     case METHOD.GET:
//       return axios.get(url, { params, ...config })
//     case METHOD.POST:
//       return axios.post(url, params, config).catch(error => {
//         // 捕获异常对象
//         if (error) {
//             this.$message.error(error.response.data.message)
//           // 如果有错误响应，提取状态码和错误信息
//           console.log('Error Status Code:', error.response.status);
//           console.log('Error Data:', error.response.data);
//         } else {
//           // 如果没有错误响应，打印其他错误信息
//           console.error('Error:', error.message);
//         }
//       })
//     case METHOD.DELETE:
//       return axios.delete(url)
//     default:
//       return axios.get(url, { params, ...config })
//   }
// }

/**
 * 设置认证信息
 * @param auth {Object}
 * @param authType {AUTH_TYPE} 认证类型，默认：{AUTH_TYPE.BEARER}
 */
function setAuthorization(auth, authType = AUTH_TYPE.BEARER) {
  auth.expireAt = new Date(new Date().getTime() + 24 * 60 * 60 * 1000) 
  switch (authType) {
    case AUTH_TYPE.BEARER:
        Cookie.set(xsrfHeaderName, 'Bearer ' + auth.token, {path:'/', expires: auth.expireAt })
      break
    case AUTH_TYPE.BASIC:
    case AUTH_TYPE.AUTH1:
    case AUTH_TYPE.AUTH2:
    default:
      break
  }
}

/**
 * 移出认证信息
 * @param authType {AUTH_TYPE} 认证类型
 */
function removeAuthorization(authType = AUTH_TYPE.BEARER) {
  switch (authType) {
    case AUTH_TYPE.BEARER:
      Cookie.remove(xsrfHeaderName)
      break
    case AUTH_TYPE.BASIC:
    case AUTH_TYPE.AUTH1:
    case AUTH_TYPE.AUTH2:
    default:
      break
  }
}

/**
 * 检查认证信息
 * @param authType
 * @returns {boolean}
 */
function checkAuthorization(authType = AUTH_TYPE.BEARER) {
  switch (authType) {
    case AUTH_TYPE.BEARER:
      if (Cookie.get(xsrfHeaderName)) {
        return true
      }
      break
    case AUTH_TYPE.BASIC:
    case AUTH_TYPE.AUTH1:
    case AUTH_TYPE.AUTH2:
    default:
      break
  }
  return false
}

/**
 * 加载 axios 拦截器
 * @param interceptors
 * @param options
 */
function loadInterceptors(interceptors, options) {
  const { request, response } = interceptors
  // 加载请求拦截器

  request.forEach(item => {
    let { onFulfilled, onRejected } = item
    if (!onFulfilled || typeof onFulfilled !== 'function') {
      onFulfilled = config => config
    }
    if (!onRejected || typeof onRejected !== 'function') {
      onRejected = error => Promise.reject(error)
    }
    axios.interceptors.request.use(function (config) {// 在发送请求之前做些什么
      return config;
    }, function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    })
    // axios.interceptors.request.use(
    //   config => {
    //     // 在每次请求前都调用一次特定的后端接口
    //     api.post('https://your-api-base-url.com/log-request', { timestamp: new Date() });
    //     return onFulfilled(config, options);
    //   },
    //   error => onRejected(error, options)
    // );
  })
  // 加载响应拦截器
  response.forEach(item => {
    let { onFulfilled, onRejected } = item
    if (!onFulfilled || typeof onFulfilled !== 'function') {
      onFulfilled = response => response
    }
    if (!onRejected || typeof onRejected !== 'function') {
      onRejected = error => Promise.reject(error)
    }
    axios.interceptors.response.use(function (response) {// 在发送请求之前做些什么
      return response;
    }, function (error) {
      console.log(options)
      return Promise.reject(error,options);
    })
    // axios.interceptors.response.use(
    //   response => onFulfilled(response, options),
    //   error => onRejected(error, options)
    // )
  })
}

/**
 * 解析 url 中的参数
 * @param url
 * @returns {Object}
 */
function parseUrlParams(url) {
  const params = {}
  if (!url || url === '' || typeof url !== 'string') {
    return params
  }
  const paramsStr = url.split('?')[1]
  if (!paramsStr) {
    return params
  }
  const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
  for (let i = 0; i < paramsArr.length / 2; i++) {
    const value = paramsArr[i * 2 + 1]
    params[paramsArr[i * 2]] = value === 'true' ? true : (value === 'false' ? false : value)
  }
  return params
}

export {
  METHOD,
  AUTH_TYPE,
  request,
  setAuthorization,
  removeAuthorization,
  checkAuthorization,
  loadInterceptors,
  parseUrlParams
}
